import styled from "@emotion/styled";

import BluredImageSrc from "./images/blured.png";
import NFTImageSrc from "./images/nft.png";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const LeftImageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;

  background-image: url(${BluredImageSrc});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const RightImageContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;

  background-image: url(${NFTImageSrc});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1100px) {
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
  }
`;

export const BuyContainer = styled.div`
  position: absolute;
  width: 1213px;
  left: 160px;
  bottom: 0;

  margin-bottom: 40px;

  @media (max-width: 1100px) {
    left: 16px;
    width: calc(100% - 32px);

    margin-bottom: 40px;
  }
`;

export const BuyDescriptionContainer = styled.div`
  width: 905px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 77px;

  color: #ede0d4;

  @media (max-width: 1100px) {
    font-size: 32px;
    line-height: 39px;
    width: 100%;
  }
`;

export const BuyButton = styled.div`
  margin-top: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  width: 179px;
  height: 59px;

  background: #ded1c7;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  text-transform: uppercase;

  color: #000000;

  cursor: pointer;

  @media (max-width: 1100px) {
    width: 127px;
    height: 44px;
    font-size: 20px;
    line-height: 24px;
  }
`;

export const NotifyContainer = styled.div`
  margin-top: 24px;
`;

export const EmailInput = styled.input`
  width: 325px;
  height: 44px;

  border: 5px solid #ded1c7;
  background-color: transparent;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;

  color: rgba(222, 209, 199, 0.5);

  padding-left: 20px;
  padding-right: 20px;
`;

export const NotifyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  width: 200px;
  height: 40px;

  background: #ded1c7;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  text-transform: uppercase;

  color: #000000;

  cursor: pointer;

  @media (max-width: 1100px) {
    width: 127px;
    height: 34px;
    font-size: 20px;
    line-height: 24px;
  }
`;
