import { useEffect, useState } from "react";
import {
  BuyButton,
  BuyContainer,
  BuyDescriptionContainer,
  Container,
  EmailInput,
  LeftImageContainer,
  NotifyButton,
  NotifyContainer,
  RightImageContainer,
} from "./ThirdSlide.styled";
import axios from "axios";

export const ThirdSlide = () => {
  const [showNotify, setShowNotify] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [sending, setSending] = useState(false);
  const [email, setEmail] = useState("");

  const ethereumProvider = !!(window as any).ethereum;
  useEffect(() => {
    (global as any)?.gtag?.("set", "user_properties", {
      ethereum_provider: ethereumProvider,
    });
  }, []);

  const buyHandler = () => {
    setShowNotify(true);

    (global as any)?.gtag?.("event", "buy_click", {
      ethereum_provider: ethereumProvider,
    });
  };

  const notifyClickHandler = async () => {
    if (sending || !email) return;
    setSending(true);

    await fetch(
      "https://script.google.com/macros/s/AKfycbxwBeYDFaTeGjkCwoe29jg1eDuXvPlA11Ldxx_x5iDz47PVUY7BvnebWTY1KXq6ENqcQg/exec",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: `date=${new Date().toISOString()}&email=${encodeURIComponent(
          email
        )}&name=${ethereumProvider}`,
      }
    );
    setShowThanks(true);

    (global as any)?.gtag?.("event", "notify_click", {
      email,
      ethereum_provider: ethereumProvider,
    });
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Container>
      <LeftImageContainer />
      <RightImageContainer />
      <BuyContainer>
        <BuyDescriptionContainer>
          Each of the 4,143 NFTs gives you access to a single photo from my
          special photoset. There are 16 photos in total. Want to see them all?
        </BuyDescriptionContainer>
        {!showNotify ? (
          <BuyButton id="buy_nft" onClick={buyHandler}>
            BUY NFT
          </BuyButton>
        ) : (
          <NotifyContainer>
            <EmailInput
              placeholder="your-email@domain.com"
              onChange={emailChangeHandler}
            />
            {!showThanks ? (
              <NotifyButton id="notify_me" onClick={notifyClickHandler}>
                {sending ? "..." : "NOTIFY ME"}
              </NotifyButton>
            ) : (
              <NotifyButton>THANK YOU</NotifyButton>
            )}
          </NotifyContainer>
        )}
      </BuyContainer>
    </Container>
  );
};
