import {
  Container,
  LargeTitleContainer,
  MainPhoto,
  TaglineContainer,
} from "./FirstSlide.styled";

export const FirstSlide = () => {
  return (
    <Container>
      <LargeTitleContainer>ABBY C</LargeTitleContainer>
      <MainPhoto />
      <TaglineContainer>A Diamond for Your NFT Collection</TaglineContainer>
    </Container>
  );
};
