import styled from "@emotion/styled";
import MainPhotoImage from "./MainPhoto.png";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const MainPhoto = styled.div`
  position: absolute;
  width: 100%;
  height: 95%;

  background-image: url(${MainPhotoImage});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 600px) {
    width: 600px;
    left: 50%;
    margin-left: -300px;
  }
`;

export const LargeTitleContainer = styled.div`
  position: absolute;
  width: 1912px;
  height: 620px;
  left: 80px;
  bottom: -150px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 512px;
  line-height: 620px;
  /* identical to box height */
  text-transform: uppercase;

  background: linear-gradient(113.73deg, #827164 -75.61%, #0f0c09 128.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const TaglineContainer = styled.div`
  position: absolute;
  width: 600px;
  height: 348px;
  left: 50%;
  top: 50%;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 116px;

  color: #ded1c7;

  @media (max-width: 1200px) {
    font-size: 55px;
    line-height: 67px;
    width: 346px;
    margin-left: -173px;
  }
`;
