import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const DescriptionContainer = styled.div`
  position: absolute;
  width: calc(100% - 200px);
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 100px 0 100px;

  font-family: "Cormorant";
  font-style: italic;
  font-weight: 500;
  font-size: 80px;
  line-height: 97px;
  text-align: center;

  color: #ded1c7;

  @media (max-width: 1200px) {
    font-size: 48px;
    line-height: 58px;

    width: calc(100% - 40px);
    padding: 0 20px 0 20px;
  }
`;
