import { Container, DescriptionContainer } from "./SecondSlide.styled";

export const SecondSlide: React.FC<React.PropsWithChildren> = () => {
  return (
    <Container>
      <DescriptionContainer>
        Amazing girl who has won the hearts of men all over the world. Her
        mystery and stunning beauty have driven them insane. Being an
        inspiration for artist and writers, she become the living icon for their
        works.
      </DescriptionContainer>
    </Container>
  );
};
