import { Global, css } from "@emotion/react";
import { Container } from "./Main.styled";

export const Main: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Global
        styles={css`
          html {
            margin: 0;
            padding: 0;
            background-color: #0f0c09;
          }

          body {
            margin: 0;
            padding: 0;
          }
        `}
      />
      {children}
    </Container>
  );
};
