import React from "react";
import { FirstSlide } from "./components/FirstSlide";
import { SecondSlide } from "./components/SecondSlide";
import { ThirdSlide } from "./components/ThirdSlide";
import { Main } from "./layouts/main";

function App() {
  return (
    <Main>
      <FirstSlide />
      <SecondSlide />
      <ThirdSlide />
    </Main>
  );
}

export default App;
